<template>
  <div class="rich-form-content">
    <Toolbar
        class="toolbar"
        :editor="editor"
        :defaultConfig="toolbarConfig"
        :mode="mode"
    />
    <Editor
        style="height: 500px; overflow-y: hidden;"
        v-model="html"
        :defaultConfig="editorConfig"
        :mode="mode"
        @onCreated="onCreated"
    />
  </div>
</template>

<script>
import {Editor, Toolbar} from '@wangeditor/editor-for-vue'

import('@wangeditor/editor/dist/css/style.css')

export default ({
  components: {Editor, Toolbar},
  props: {
    htmlData: {
      type: String,
      default: '<p>...</p>'
    }
  },
  computed: {
    html: {
      get() {
        return this.htmlData
      },
      set(newVal) {
        this.$emit('update:htmlData', newVal)
      }
    }
  },
  data() {
    return {
      editor: null,
      // html: '<p>hello</p>',
      toolbarConfig: {
        toolbarKeys:
            ['bold', 'underline', 'italic', 'through', '|',    'continueWrite', 'modifyWrite', 'abbrWrite', 'extendWrite', '|', 'undo', 'redo', '|', 'exportContent'],
            customMenu: {
              text: 'modifyWrite',
              onClick: this.customMenuClick
            }
      },
      editorConfig: {placeholder: '请输入内容...'},
      mode: 'default', // or
    }
  },
  methods: {
    customMenuClick(){
      this.$message.success("123")
    },
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
  },
  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  },
})
</script>

<style scoped lang="scss">

</style>